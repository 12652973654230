@import '@react/common.scss';

.cmp-filterbar__footer {
  @media #{$mq-desktop} {
    display: none;
  }

  @media #{$mq-mobile} {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: var(--spacing-md) var(--spacing-lg);
    border-top: var(--border-xs) solid var(--color-gray-300);
    background-color: var(--color-gray-50);
    z-index: 1;
  }
}
