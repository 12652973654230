@import '@react/common.scss';

.cmp-filterable-list__card {
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  background-color: var(--color-gray-50);
  min-width: 0;
  border-radius: var(--border-radius-m);

  &:hover {
    .cmp-filterable-list__card-title {
      color: var(--color-primary-500);
    }

    .cmp-filterable-list__card-read-more--icon {
      background-color: var(--color-primary-500);
    }
  }

  &-link {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    &:focus {
      outline: var(--border-m) solid var(--color-focus-500);
      border-radius: var(--border-radius-m);
    }
  }

  &-tag {
    @include bold-xl;

    position: absolute;
    top: var(--spacing-lg);
    left: 0;
    z-index: 1;
    background: var(--color-primary-500);
    color: var(--color-white);
    padding: var(--spacing-xxs) var(--spacing-xs);
    border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
  }

  :not(:has(.cmp-filterable-list__card-image)) .cmp-filterable-list__card-tag {
    top: unset;
    left: unset;
    right: 0;
    bottom: var(--spacing-xxxl);
    border-radius: var(--border-radius-xs) 0 0 var(--border-radius-xs);
  }

  &-image {
    position: relative;
    aspect-ratio: 16/9;
    height: fit-content;

    img {
      border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
    }
  }

  &-image--no-object-fit img {
    object-fit: none;
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
    padding: var(--spacing-md);

    .cmp-filterable-list__card-description,
    .cmp-filterable-list__card-sub-info {
      margin-bottom: var(--spacing-xxs);
    }
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
    max-height: var(--spacing-4xl);
    overflow: hidden;
    margin-bottom: var(--spacing-sm);
  }

  &-title {
    @include heading-5;

    color: var(--color-gray-900);
    margin: 0 0 var(--spacing-lg);
  }

  &-description,
  &-sub-info {
    @include regular-md;

    color: var(--color-gray-900);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    line-clamp: 6;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &-sub-info {
    margin-top: auto;

    span:not(:last-child)::after {
      content: '|';
      display: inline-block;
      padding: 0 var(--spacing-xs);
    }
  }

  &-read-more {
    @include bold-lg;

    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    vertical-align: middle;
    color: var(--color-white);

    .cmp-filterable-list__card-read-more--icon {
      display: flex;
      border-radius: var(--border-l);
      background-color: var(--color-gray-900);

      &::before {
        margin-right: 0;
      }
    }
  }

  &:not(:has(.cmp-filterable-list__card-sub-info)) .cmp-filterable-list__card-read-more {
    margin-top: auto;
  }

  &-meta {
    @include bold-md;

    color: var(--color-gray-900);
    margin-bottom: var(--spacing-xs);
  }

  &--aspect-1-1 {
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: 100%;
    }
  }
}
