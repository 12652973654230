@import '@react/common.scss';

.cmp-filterable-list__asset-search {
  @include regular-md();

  margin-bottom: var(--spacing-lg);

  .cmp-filterable-list__card-link {
    height: auto;
  }

  &--content {
    margin-top: auto;
    padding: 0 var(--spacing-lg) var(--spacing-lg);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }

  &--action-buttons {
    display: flex;
    gap: var(--spacing-md);

    .cmp-button {
      color: var(--color-black);
      min-width: unset;

      &:hover {
        color: var(--color-primary-500);
      }

      &.hidden {
        display: none;
      }
    }
  }

  .cmp-filterable-list__card {
    &-title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      word-break: break-word;
    }

    &-description {
      line-clamp: 3;
      -webkit-line-clamp: 3;
    }

    &-image {
      background-color: var(--color-gray-50);
    }
  }

  .cmp-filterable-list__table-tbody .cmp-filterable-list__table-row {
    border-top: var(--spacing-xxxs) solid var(--color-gray-100);

    .cmp-filterable-list__table-td::before {
      content: none;
    }
  }

  .cmp-filterable-list__table-th {
    @include regular-xs();

    padding: var(--spacing-sm) var(--spacing-md) var(--spacing-sm) 0;
    width: 20%;
    text-transform: uppercase;
    background: none;
    border-right: none;

    &:first-child {
      border-left: none;
    }
  }

  .cmp-filterable-list__table-td {
    padding: var(--spacing-md) var(--spacing-md) var(--spacing-md) 0;
    width: 20%;
    border: none;
    max-width: unset;
    min-width: unset;
    white-space: pre;
    text-wrap: auto;
    vertical-align: middle;

    &--table-cell {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      display: -moz-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      word-break: break-word;
    }

    .cmp-filterable-list__list-link {
      text-decoration: none;
      color: var(--color-primary-500);

      &:hover {
        color: var(--color-primary-500);
      }
    }

    .cmp-filterable-list__asset-search--action-buttons {
      padding: var(--spacing-xxs) 0;
    }
  }

  .cmp-filterable-list__table-th:last-child,
  .cmp-filterable-list__table-td:last-child {
    padding-right: 0;
  }

  @media #{$mq-mobile} {
    .cmp-filterable-list__table-row {
      display: block;
      padding: var(--spacing-md) 0;
    }

    .cmp-filterable-list__table-td {
      display: block;
      width: 100%;
      padding: var(--spacing-xxs) 0;

      &:first-child {
        max-width: 50%;
      }
    }
  }
}

.cmp-filterable-list:has(.cmp-filterable-list__asset-search) {
  display: flex;
  flex-direction: column;
}
