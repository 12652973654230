@import '@react/common.scss';

.cmp-filterbar__active-filters {
  display: flex;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-xs);

  @media #{$mq-mobile} {
    flex-direction: column;
    gap: var(--spacing-md);
  }

  .cmp-filterbar__count {
    @include bold-md();

    white-space: nowrap;
    align-self: center;

    @media #{$mq-mobile} {
      align-self: start;
    }
  }

  .cmp-filterbar__button--clear-all {
    @media #{$mq-mobile} {
      display: flex;
      flex-basis: 100%;
    }
  }

  &:not(:has(.cmp-filterbar__tags)) {
    @media #{$mq-mobile} {
      margin-bottom: 0;
    }
  }
}
