@import '@react/common.scss';

.cmp-filterable-list__card--list-type {
  padding: 0;
  margin: var(--spacing-md) 0 0;
  background: none;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  .cmp-filterable-list__card-content {
    flex: 1;
  }

  .cmp-filterable-list__card-link {
    gap: var(--spacing-md);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .cmp-filterable-list__card-meta {
    @include regular-sm;

    margin-bottom: var(--spacing-xs);
  }

  .cmp-filterable-list__card-title {
    margin-bottom: var(--spacing-xs);
  }
}

.cmp-filterable-list__related-jobs {
  .cmp-filterable-list__card--list-type {
    box-shadow: var(--box-shadow);

    .cmp-filterable-list__card-link {
      padding: var(--spacing-md) var(--spacing-sm);
    }

    &:hover {
      box-shadow: var(--box-shadow);
    }
  }
}
