@import '@react/common.scss';

.cmp-filterbar__tags {
  display: inline-flex;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
  align-items: center;
  border: 0;
  padding: 0;
  color: var(--color-gray-900);
  transition: 0.2s ease-in-out 0s;
}
