@import '@site/styles/foundations/media_queries.scss';

.cmp-filterable-list__section-header {
  margin: 0;
  padding: var(--spacing-lg) 0 var(--spacing-md);
  width: 100%;
  border-bottom: var(--border-xs) solid var(--color-gray-300);

  &:not(:first-of-type) {
    padding-top: var(--spacing-xl);
  }
}
