@import '@react/common.scss';

.cmp-subfilter {
  cursor: pointer;
  padding: var(--spacing-md) 0;
  display: inline-flex;
  flex-direction: column;
  min-width: toRem(140px);
  max-width: 50%;
  position: relative;

  &:has(.cmp-subfilter__items-wrapper) {
    .cmp-subfilter__label {
      border-radius: var(--border-l) var(--border-l) 0 0;
      background-color: var(--color-gray-50);
      border-color: var(--color-gray-300);
    }

    .cmp-subfilter__items {
      border-color: var(--color-gray-300);
    }
  }

  &:not(:has(.cmp-subfilter__items-wrapper)) .cmp-subfilter__label {
    border-bottom: var(--border-xs) solid var(--color-gray-500);

    &:hover {
      border-color: var(--color-black);
    }
  }

  &__separator {
    width: calc(100% - var(--spacing-lg));
    display: flex;
    height: var(--spacing-xxxs);
    background: var(--color-gray-700);
    margin: 0 auto var(--spacing-xs);
  }

  &.cmp-subfilter--is-disabled {
    pointer-events: none;
    color: var(--color-gray-300);
    background-color: var(--color-gray-50);

    .cmp-subfilter__label {
      border: var(--border-xs) solid var(--color-gray-300);
    }
  }
}

.cmp-subfilter__label {
  @include regular-md;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spacing-sm) 0;
  height: var(--spacing-xxl);
  border: var(--border-xs) solid var(--color-gray-500);
  border-bottom: none;
  border-radius: var(--border-l);
  background-color: var(--color-white);

  &:hover {
    background-color: var(--color-gray-50);
    border-color: var(--color-black);

    &::after {
      color: var(--color-primary-500);
    }
  }

  &::after {
    font-family: var(--icon-font);
    content: var(--icon-keyboard_arrow_down);
    font-size: var(--font-size-icon-md);
    line-height: initial;
    margin-left: var(--spacing-xxs);
  }

  &[aria-expanded='true']::after {
    content: var(--icon-keyboard_arrow_up);
  }

  &:focus {
    outline: var(--border-m) solid var(--color-focus-500);
  }
}

.cmp-subfilter__items {
  position: absolute;
  background-color: var(--color-gray-50);
  width: 100%;
  border: var(--border-xs) solid var(--color-gray-300);
  border-top: none;
  border-radius: 0 0 var(--border-l) var(--border-l);

  @media #{$mq-desktop} {
    @include regular-md;

    padding: 0 0 var(--spacing-xs) 0;
  }

  @media #{$mq-mobile} {
    @include regular-sm;
  }
}

.cmp-subfilter__item {
  @include regular-md;

  padding: var(--spacing-xs) var(--spacing-md);
  white-space: nowrap;
  width: 100%;

  &:hover {
    background-color: var(--color-gray-100);
  }

  &--is-active {
    background-color: var(--color-gray-50);
  }
}
