@import '@react/common.scss';

.cmp-filterbar__filter-dropdown {
  position: relative;

  @media #{$mq-desktop} {
    width: calc(100% / 3 - var(--spacing-md));
  }
}

.cmp-filterbar__dropdown-button {
  @include bold-md;

  border: var(--border-xs) solid var(--color-gray-500);
  border-radius: var(--border-l);
  padding: var(--spacing-xs) var(--spacing-sm);
  width: 100%;
  height: var(--spacing-xxl);
  text-align: left;
  background-color: var(--color-white);
  display: inline-flex;
  align-items: center;

  @media #{$mq-desktop} {
    // Make outline stand above the children items
    z-index: 1;
    position: relative;
  }

  &--selected-checkboxes {
    display: none;
  }

  &:focus,
  &:active {
    outline: var(--border-m) solid var(--color-focus-500);
    border-radius: var(--border-l);
  }

  &::after {
    font-family: var(--icon-font);
    content: var(--icon-keyboard_arrow_down);
    font-size: var(--font-size-icon-md);
    font-weight: var(--font-weight);
    line-height: normal;
    pointer-events: none;
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 0;
    margin-left: auto;
  }

  @media #{$mq-mobile} {
    &::after {
      content: var(--icon-keyboard_arrow_right);
    }

    &--selected-checkboxes {
      background-color: var(--color-gray-700);
      color: var(--color-white);
      margin-left: var(--spacing-xs);
      min-width: toRem(20px);
      height: toRem(20px);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0 var(--spacing-xxs);
    }
  }

  &--desktop-label {
    @media #{$mq-desktop} {
      width: var(--spacing-md);
      height: var(--spacing-xxxs);
      background-color: var(--color-black);
    }
  }

  &--mobile-label {
    @media #{$mq-desktop} {
      display: none;
    }
  }
}

.cmp-filterbar__dropdown-container {
  display: none;
}

.cmp-filterbar__dropdown-footer {
  display: none;
}

.cmp-filterbar__dropdown {
  position: absolute;
  width: 100%;
  background-color: var(--color-gray-50);

  &-label {
    display: block;
    margin-bottom: var(--spacing-xxs);

    @include regular-md;

    @media #{$mq-mobile} {
      display: none;
    }
  }

  &--is-open {
    z-index: 2;
    box-shadow: var(--box-shadow-xs);

    .cmp-filterbar__dropdown-button {
      outline: var(--border-m) solid var(--color-focus-500);
      border-radius: var(--border-l);

      &::after {
        transform: rotate(180deg);
      }
    }

    .cmp-filterbar__dropdown-container {
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      @media #{$mq-desktop} {
        padding: var(--spacing-xs) 0;
      }

      .cmp-filterbar__dropdown-checkboxes {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      .cmp-filterbar__checkbox-item {
        background-color: var(--color-gray-50);

        &:hover:has(.cmp-filterbar__checkbox-item) > .cmp-filterbar__checkbox-item-wrapper,
        &:has(.cmp-filterbar__checkbox-item:hover) > .cmp-filterbar__checkbox-item-wrapper {
          background-color: var(--color-gray-100);
        }

        &:not(:has(.cmp-filterbar__checkbox-item)):hover,
        &:not(:has(.cmp-filterbar__child-checkboxes--is-expanded)):hover {
          background-color: var(--color-gray-100);
        }
      }

      .cmp-filterbar__filter-text {
        padding: var(--spacing-xs) 0 var(--spacing-xs);
      }

      .cmp-filterbar__search-input-wrapper {
        width: 100%;
        margin-bottom: 0;
      }

      @media #{$mq-desktop} {
        &:has(.cmp-filterbar__dropdown-checkboxes) {
          max-height: 25vh;
        }
      }
    }

    @media #{$mq-desktop} {
      .cmp-filterbar__dropdown-rangeslider {
        padding: 0 var(--spacing-xs);
      }
    }

    .cmp-filterbar__dropdown-footer {
      display: flex;
      padding: var(--spacing-sm) var(--spacing-md);
      justify-content: space-between;
      border-top: var(--border-xs) solid var(--color-gray-100);

      @media #{$mq-mobile} {
        padding: var(--spacing-lg) 0 0;
      }
    }
  }

  &--is-disabled {
    pointer-events: none;
    border: var(--border-xs) solid var(--color-gray-300);
    border-radius: var(--border-l);
    color: var(--color-gray-300);

    .cmp-filterbar__dropdown-button {
      border: var(--border-xs) solid var(--color-gray-300);
      background-color: var(--color-gray-50);
    }

    .cmp-filterbar__dropdown-button--desktop-label {
      background-color: var(--color-gray-300);
    }

    .cmp-filterbar__dropdown-button--selected-checkboxes {
      background-color: var(--color-gray-300);
    }
  }

  @media #{$mq-mobile} {
    &--is-open {
      box-shadow: none;

      .cmp-filterbar__dropdown-container {
        background-color: transparent;
        max-height: none;
      }

      .cmp-filterbar__dropdown-button {
        display: none;
      }

      .cmp-filterbar__dropdown-checkboxes {
        max-height: 50vh;
      }

      .cmp-filterbar__dropdown-footer {
        border-top: none;

        .cmp-filterbar__button--select {
          display: none;
        }
      }

      .cmp-filterbar__active-filters {
        display: none;
      }
    }
  }
}
