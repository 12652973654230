@import '@react/common.scss';

.cmp-filterbar__header {
  @media #{$mq-desktop} {
    @include bold-md;

    margin: var(--spacing-sm) 0 var(--spacing-md);

    &--is-close,
    &--is-back {
      display: none;
    }
  }

  @media #{$mq-mobile} {
    @include bold-lg;

    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: var(--spacing-md) var(--spacing-lg);
    border-bottom: var(--border-xs) solid var(--color-gray-300);

    &-title {
      display: flex;
      justify-content: center;
    }

    &--is-hidden {
      visibility: hidden;
    }

    &--is-close,
    &--is-back {
      display: flex;
      align-items: center;
      flex-basis: 25%;
    }

    &--is-back {
      color: var(--color-primary-500);

      span {
        display: flex;
      }
    }

    &--is-close {
      justify-content: flex-end;

      &:focus-visible {
        outline: none;

        .icon-close {
          outline: var(--border-m) solid var(--color-focus-500);
        }
      }

      .icon-close::before {
        margin-right: 0;
      }
    }
  }
}
