@import '@react/common.scss';

.cmp-filterbar__secondary-filters-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: var(--spacing-lg);

  &::before {
    content: var(--icon-keyboard_arrow_down);
    font-family: var(--icon-font);

    @media #{$mq-desktop} {
      font-size: var(--font-size-icon-md);
    }

    @media #{$mq-mobile} {
      font-size: var(--font-size-icon-xs);
    }
  }

  &::after {
    content: '';
    border-bottom: var(--spacing-xxxs) solid var(--color-gray-300);
    display: flex;
    flex-basis: 100%;
    margin-left: var(--spacing-xs);
  }

  &-title {
    margin-left: var(--spacing-xxs);

    @media #{$mq-desktop} {
      @include bold-md;
    }

    @media #{$mq-mobile} {
      @include regular-md;
    }
  }

  &--is-toggled {
    &::before {
      content: var(--icon-keyboard_arrow_up);
    }
  }

  @media #{$mq-mobile} {
    margin: 0 var(--spacing-lg) var(--spacing-md);
  }
}
