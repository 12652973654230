@import '@react/common.scss';

.cmp-filterable-list__list-events-link {
  @include bold-lg();

  color: var(--color-gray-900);
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-xxs) var(--spacing-xs) 0;

  @media #{$mq-mobile} {
    top: var(--spacing-md);
    width: auto;
  }

  &:hover {
    color: var(--color-primary-500);
  }

  &--upcoming-event {
    justify-content: flex-end;
  }

  &--past-event {
    justify-content: flex-start;
  }
}
