@import '@react/common.scss';

:root {
  --testimonial-image-height: 11rem;
  --testimonial-image-width: 11rem;
}

.cmp-filterable-list {
  margin-bottom: var(--spacing-lg);

  &__cards {
    display: grid;
    padding: 0;
    position: relative;
    gap: var(--spacing-md);
    margin-top: var(--spacing-lg);

    @media #{$mq-mobile} {
      @for $i from 1 through 6 {
        &.cmp-filterable-list--#{$i}-columns {
          @if ($i == 1) {
            @include gridColumns(1);
          }

          @else {
            @include gridColumns(2);
          }
        }
      }
    }

    @media #{$mq-desktop} {
      @for $i from 1 through 6 {
        &.cmp-filterable-list--#{$i}-columns {
          @include gridColumns($i);
        }
      }
    }

    @media #{$mq-1} {
      column-gap: var(--spacing-xs);
      row-gap: var(--spacing-md);
      margin-top: 0;

      @for $i from 1 through 6 {
        &.cmp-filterable-list--#{$i}-columns {
          @include gridColumns(1);
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;

    &-events.cmp-filterable-list__list:not(.u-full-width) {
      max-width: toRem(792px);
      margin: 0 auto var(--spacing-lg);
      padding: 0;
    }
  }

  &__video-type {
    gap: var(--spacing-md);

    .cmp-filterable-list__card {
      &-meta {
        @include regular-lg;

        text-transform: none;
      }
    }
  }

  &__related-jobs {
    &.cmp-filterable-list--3-columns {
      @media #{$mq-mobile} {
        @include gridColumns(1);
      }
    }
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-md);
    margin: var(--spacing-lg) 0;

    &.cmp-filterable-list__button--center {
      justify-content: center;
    }

    &.cmp-filterable-list__button--left {
      justify-content: flex-start;
    }

    &.cmp-filterable-list__button--right {
      justify-content: flex-end;
    }
  }

  &__list-link {
    color: var(--color-primary-500);
    display: block;
  }

  &__results {
    @include regular-xl;

    display: block;
    text-align: center;
    margin-bottom: var(--spacing-md);
  }

  &--error,
  &--empty {
    margin-bottom: var(--spacing-lg);
  }
}
