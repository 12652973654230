@import '@react/common.scss';

@include remove-grid-content('.entitylist');

.list-wrapper {
  position: relative;

  .cmp-filterbar__container {
    @media #{$mq-desktop} {
      margin: var(--spacing-xl) 0 var(--spacing-4xl);

      &.cmp-filterbar__quickfilter {
        margin: 0;
      }
    }

    @media #{$mq-mobile} {
      padding: 0 0 var(--spacing-lg);
    }

    .cmp-filterbar__separator {
      border-bottom: var(--border-xs) solid var(--color-gray-50);
    }
  }

  .cmp-filterbar__quickfilter {
    .cmp-filterbar__header {
      @media #{$mq-desktop} {
        display: none;
      }
    }

    .cmp-filterbar__active-filters {
      display: none;
    }
  }

  &:not(:has(div))::after {
    content: none;
  }
}

.list-wrapper__teams-notifications {
  display: none;
  height: 0;
  width: 0;
}

.list-wrapper__teams-notifications--has-notifications {
  display: block;
}

.list-wrapper .cmp-filterbar__initial-loading-spinner {
  visibility: hidden;
}
