@import '@site/styles/foundations/typography.scss';
@import '@site/styles/foundations/media_queries.scss';

.cmp-filterable-list__table {
  display: table;
  border: none;

  @media #{$mq-desktop} {
    table-layout: fixed;
  }

  @include bold-lg();

  &--hidden-row {
    display: none;
  }

  &-row {
    box-shadow: 0 -1px 0 0 var(--color-gray-100) inset;
  }

  &-th {
    background-color: var(--color-gray-700);
    color: var(--color-white);
    padding: var(--spacing-md) var(--spacing-xs) var(--spacing-md) var(--spacing-lg);
    vertical-align: middle;
    border-right: var(--spacing-xxxs) solid var(--color-white);
    position: relative;

    &:first-child {
      border-left: var(--spacing-xxxs) solid var(--color-gray-50);
    }

    &:last-child {
      border-right: var(--spacing-xxxs) solid var(--color-gray-50);
    }

    &--wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-wrapper {
    padding-right: var(--spacing-xxxs);

    @media #{$mq-mobile} {
      overflow-x: auto;
      background: linear-gradient(270deg, rgb(0 0 0 / 50%) 0%, var(--color-white) 100%);
      background-position-x: right;
      background-repeat: no-repeat;
      background-size: var(--spacing-xl);
    }
  }

  &-tbody {
    a {
      width: 100%;
      display: block;
    }

    .cmp-filterable-list__table-row {
      &:hover {
        outline: var(--border-xs) solid var(--color-primary-500);
      }

      td:first-child,
      th:first-child,
      td:last-child,
      th:last-child {
        position: relative;
      }

      &:hover td:first-child::before,
      th:first-child::before {
        transform: scaleX(1);
      }

      td:first-child::before,
      th:first-child::before {
        position: absolute;
        top: calc(var(--spacing-xxxs) * -1);
        bottom: calc(var(--spacing-xxxs) * -1);
        left: calc(var(--spacing-xxxs) * -1);
        width: var(--spacing-xxs);
        background: var(--color-primary-500);
        content: '';
        transition: transform 0.25s;
        transform: scaleX(0);
        transform-origin: 0 0;
      }
    }
  }

  &-td {
    padding: var(--spacing-md) var(--spacing-md) var(--spacing-md) var(--spacing-lg);
    border: var(--spacing-xxxs) solid var(--color-gray-100);
    vertical-align: middle;
    overflow-wrap: break-word;

    &:focus,
    &:has(a:focus) {
      outline: var(--border-m) solid var(--color-focus-500);
      border-radius: var(--border-s);
    }

    .cmp-filterable-list__list-link {
      text-decoration: underline;

      &:hover {
        color: var(--color-gray-900);
      }

      &:focus {
        outline: none;
      }

      &:has(.cmp-button__icon) {
        text-decoration: none;
        display: flex;
        justify-content: center;
      }
    }
  }

  /* Sortable Tables */
  &--sortable {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &:focus-within {
      outline: var(--border-m) solid var(--color-focus-500);
      border-radius: var(--border-s);
    }

    &-button:focus {
      outline: none;

      .cmp-filterable-list__table--sortable-icon {
        fill: var(--color-primary-500);
      }
    }

    &-icon {
      display: flex;
      flex-flow: column nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      fill: var(--color-white);
      padding: toRem(5px);
      width: var(--spacing-lg);
      height: var(--spacing-lg);
      cursor: pointer;

      &:hover {
        fill: var(--color-primary-500);
      }

      &-down {
        padding-top: 0;
        margin-top: calc(-1 * var(--spacing-xxs));
      }

      &-up {
        padding-bottom: 0;
        margin-bottom: calc(-1 * var(--spacing-xxs));
      }
    }
  }
}

.cmp-filterable-list__table-td .cmp-abbreviation {
  text-decoration: none;
}

.cmp-filterable-list__table-wrapper:has(.cmp-filterable-list__jobs) {
  overflow-x: auto;
}
