@import '@site/styles/foundations/media_queries.scss';
@import '@site/styles/foundations/typography.scss';

.cmp-sortbar__dropdown {
  position: relative;
  cursor: pointer;
}

.cmp-sortbar__dropdown-label {
  display: flex;
  align-items: center;

  @media #{$mq-desktop} {
    @include bold-md;
  }

  @media #{$mq-mobile} {
    @include bold-sm;
  }

  &::after {
    font-family: var(--icon-font);
    content: var(--icon-keyboard_arrow_down);
    font-size: var(--font-size-icon-md);
    line-height: initial;
    margin-left: var(--spacing-xxs);
  }

  &[aria-expanded='true']::after {
    content: var(--icon-keyboard_arrow_up);
  }
}

.cmp-sortbar__dropdown-items {
  position: absolute;
  z-index: 1;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-xs);

  @media #{$mq-desktop} {
    @include regular-md;

    padding: var(--spacing-xs) 0;
  }

  @media #{$mq-mobile} {
    @include regular-sm;
  }
}

.cmp-sortbar__dropdown-item {
  @include bold-md;

  padding: toRem(6px) var(--spacing-md);
  white-space: nowrap;

  &:hover {
    background-color: var(--color-gray-100);
  }
}
