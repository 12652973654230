@import '@site/styles/foundations/typography.scss';

.cmp-filterable-list__asset-search-info {
  @include regular-md();

  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);

  &--content {
    @include bold-md();
  }
}
