@import '@react/common.scss';

.cmp-multi-slider {
  margin: calc(var(--spacing-sm) * -1) auto auto;
  text-align: center;
  position: relative;
  width: 100%;

  input,
  textarea {
    border: none;
  }
  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='range'] {
    appearance: none;
    width: 100%;
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='range']:focus {
    outline: none;
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='range']:focus::-webkit-slider-runnable-track,
  input[type='range']:focus::-ms-fill-lower,
  input[type='range']:focus::-ms-fill-upper {
    background: var(--color-gray-500);
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: var(--border-s);
    cursor: pointer;
    background: var(--color-gray-500);
    border-radius: var(--border-xs);
    box-shadow: none;
    border: 0;
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='range']::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0 0 0 var(--color-black);
    border: var(--border-s) solid var(--color-gray-500);
    height: var(--font-size-icon-md);
    width: var(--font-size-icon-md);
    border-radius: 50%;
    background: var(--color-white);
    cursor: pointer;
    appearance: none;
    margin-top: calc(var(--spacing-sm) * -1);
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='range']::-moz-range-track {
    width: 100%;
    height: var(--border-s);
    cursor: pointer;
    background: var(--color-gray-500);
    border-radius: var(--border-xs);
    box-shadow: none;
    border: 0;
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='range']::-moz-range-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0 0 0 var(--color-black);
    border: var(--border-xs) solid var(--color-gray-500);
    height: var(--font-size-icon-md);
    width: var(--font-size-icon-md);
    border-radius: 50%;
    background: var(--color-white);
    cursor: pointer;
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='range']::-ms-track {
    width: 100%;
    height: var(--border-s);
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='range']::-ms-fill-lower,
  input[type='range']::-ms-fill-upper {
    background: var(--color-gray-500);
    border-radius: var(--border-xs);
    box-shadow: none;
    border: 0;
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  input[type='range']::-ms-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0 0 0 var(--color-black);
    height: var(--font-size-icon-md);
    width: var(--font-size-icon-md);
    border-radius: 50%;
    background: var(--color-white);
    cursor: pointer;
  }
}

.cmp-multi-slider__range {
  margin-bottom: var(--spacing-lg);

  input {
    height: toRem(14px);
    background-color: transparent;
    border-color: var(--color-gray-500);
  }

  &-minimum {
    border-left: var(--border-s) solid var(--color-gray-500);
  }

  &-maximum {
    border-right: var(--border-s) solid var(--color-gray-500);
  }
}

.cmp-multi-slider__number {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-md) 0;
  gap: var(--spacing-sm);

  &-minimum,
  &-maximum {
    position: relative;
    flex-grow: 1;
    justify-content: space-between;
    padding: var(--spacing-sm);
    border: var(--border-xs) solid var(--color-gray-700);
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    height: var(--spacing-xxl);

    @media #{$mq-tablet} {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &-minimum:focus-within,
  &-minimum:active,
  &-maximum:focus-within,
  &-maximum:active {
    outline: var(--border-m) solid var(--color-focus-500);
    border-radius: var(--border-m);
  }
}

/* stylelint-disable-next-line selector-no-qualifying-type */
.cmp-multi-slider svg,
.cmp-multi-slider input[type='range'] {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
input[type='number'] {
  @include regular-md;

  text-align: left;
  color: var(--color-gray-900);
  padding: 0 var(--spacing-xs) 0 0;

  &:focus,
  &:active {
    border: none;
    outline: none;
  }
}

/* stylelint-disable-next-line selector-no-qualifying-type */
input[type='number']:invalid,
input[type='number']:out-of-range {
  border: var(--border-s) solid var(--color-primary-500);
}

.cmp-multi-slider__number-unit {
  @include regular-md;

  color: var(--color-gray-500);
}
