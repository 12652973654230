@import '@react/common.scss';

.cmp-filter__title {
  @include separation-border(0);

  display: flex;
  padding: var(--spacing-md) 0;

  &-heading {
    @include bold-lg;

    display: flex;
    color: var(--color-gray-900);
    margin: 0;
  }
}

.list-wrapper:has(.cmp-filter__title) .cmp-teams-notifications {
  padding-top: var(--spacing-md);
}
