@import '@site/styles/foundations/typography.scss';

.cmp-filterable-list__card-tags--item {
  @include regular-sm;

  background-color: var(--color-gray-50);
  color: var(--color-gray-700);
  padding: var(--spacing-xxs) var(--spacing-sm);
  border: var(--border-xs) solid var(--color-gray-300);
  border-radius: var(--border-radius-s);
}
