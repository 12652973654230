@import '@site/styles/settings/functions.scss';
@import '@site/styles/foundations/media_queries.scss';
@import '@/site/styles/foundations/typography.scss';

.cmp-filterable-list__list-events.cmp-filterable-list__events-type--carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  background: var(--color-gray-50);
  margin-bottom: var(--spacing-lg);

  &::before {
    position: absolute;
    z-index: 1;
  }

  .cmp-carousel {
    &__pagination {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      width: var(--spacing-xl);
      height: var(--spacing-xl);
    }

    &__pagination-item {
      display: flex;
      line-height: 1;
      list-style-type: none;
      margin: 0 var(--spacing-xs);
      pointer-events: auto;
      cursor: pointer;
    }

    &__pagination-current-slide {
      @include regular-md;
    }

    &__pagination-item--button {
      margin: 0;
      width: var(--spacing-xs);
      height: var(--spacing-xs);
      background: var(--color-gray-300);
      border-radius: 50%;

      &:hover {
        background: var(--color-primary-500);
      }

      &.is-active {
        background: var(--color-black);
      }
    }
  }

  .splide__list .splide__slide {
    min-width: 45ch;
    max-width: 58.5ch;

    @media #{$mq-1} {
      min-width: 40ch;
    }
  }

  .splide__arrows {
    padding-top: var(--spacing-xxs);
    padding-bottom: var(--spacing-xl);
  }
}

.carousel.entityList {
  margin: auto;

  .cmp-filterable-list__list-events--carousel-wrapper {
    margin: 0 var(--grid-anti-margin);

    .list-wrapper {
      overflow: hidden;
      display: flex;
      justify-content: center;

      .cmp-filterable-list {
        display: flex;
        justify-content: center;
        overflow: hidden;

        &__card {
          background-color: var(--color-white);
        }

        &__card-tag {
          @include bold-xl;

          position: absolute;
          top: var(--spacing-lg);
          left: 0;
          right: unset;
          bottom: unset;
          z-index: 1;
          background: var(--color-primary-500);
          color: var(--color-white);
          padding: var(--spacing-xxs) var(--spacing-xs);
          border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
        }
      }
    }
  }
}
