@import '@site/styles/foundations/media_queries.scss';

.cmp-sortbar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  @media #{$mq-desktop} {
    margin-top: var(--spacing-xl);
  }

  @media #{$mq-mobile} {
    margin-top: var(--spacing-md);
  }
}
