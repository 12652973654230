@import '@react/common.scss';

.cmp-filterable-list__related-jobs-wrapper {
  padding: var(--spacing-xxxl) 0;

  .cmp-filterable-list__related-jobs-title {
    @include regular-4xl;

    text-align: center;
    margin-bottom: var(--spacing-xl);

    @media #{$mq-mobile} {
      @include regular-3xl;
    }
  }

  .cmp-filterable-list__related-jobs {
    margin-left: auto;
    margin-right: auto;

    @media #{$mq-3} {
      max-width: grid-spacing(3, 12, 11);
    }

    @media #{$mq-4} {
      max-width: grid-spacing(4, 12, 11);
    }

    @media #{$mq-5} {
      max-width: grid-spacing(5, 12, 11);
    }

    @media #{$mq-6} {
      max-width: grid-spacing(6, 12, 11);
    }

    .cmp-filterable-list__card--list-type {
      margin: 0;

      .cmp-filterable-list__card-link {
        padding: var(--spacing-sm) var(--spacing-lg) var(--spacing-lg);
      }
    }

    &--button {
      display: flex;
      justify-content: center;
      margin-top: var(--spacing-lg);
    }
  }
}
