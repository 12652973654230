@import '@react/common.scss';

.cmp-filterbar__button {
  width: inherit;

  &--select {
    @include button-primary();

    text-transform: capitalize;
  }

  &--reset {
    display: flex;
    align-items: center;
    color: var(--color-primary-500);
    text-transform: capitalize;

    @include bold-lg();

    &:focus {
      outline: var(--border-m) solid var(--color-focus-500);
      border-radius: var(--border-s);
    }
  }

  &--clear-all {
    color: var(--color-primary-500);
    text-decoration: underline;
    align-self: center;

    @include regular-sm();

    @media #{$mq-mobile} {
      align-self: start;
    }
  }

  &--tag {
    display: flex;
    align-items: center;
    background-color: var(--color-gray-50);
    border: var(--border-xs) solid var(--color-gray-300);
    border-radius: var(--border-xl);
    padding: var(--spacing-xxs) var(--spacing-sm);

    @include regular-sm();

    &:hover {
      color: var(--color-primary-500);
      border-color: var(--color-primary-500);

      .cmp-filterbar__button-text {
        text-decoration: underline;
      }
    }

    &:focus {
      background-color: var(--color-white);
      outline: var(--border-m) solid var(--color-focus-500);
    }

    .icon-close {
      color: var(--color-primary-500);
      display: flex;
      margin-left: var(--spacing-xs);

      @include regular-md();

      &::before {
        margin-right: 0;
      }
    }
  }

  .icon-reset {
    font-size: var(--font-size-icon-md);
  }
}

.icon-placement {
  &--before-label {
    order: 1;

    ~ .cmp-filterbar__button-text {
      order: 2;
    }
  }

  &--after-label {
    order: 2;

    ~ .cmp-filterbar__button-text {
      order: 1;
    }
  }
}
