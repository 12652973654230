.cmp-list-view-switcher {
  display: flex;
  gap: var(--spacing-lg);

  .cmp-button {
    color: var(--color-gray-300);

    &.is-active {
      color: var(--color-black);
    }

    &:hover {
      color: var(--color-primary-500);
    }
  }
}
