@import '@react/common.scss';

.cmp-filterbar__search {
  &-input-wrapper {
    display: flex;
    border: var(--border-xs) solid var(--color-gray-500);
    border-radius: var(--border-l);
    padding: 0 var(--spacing-xs);
    width: 100%;
    background-color: var(--color-white);

    @include regular-md();

    @media #{$mq-desktop} {
      width: calc(50% - var(--spacing-sm));
    }

    &:has(:focus-visible) {
      outline: var(--border-m) solid var(--color-focus-500);
    }
  }

  &-input {
    width: 100%;
    height: var(--spacing-xxl);
    padding: var(--spacing-sm) var(--spacing-xs);
    border: none;
    caret-color: var(--color-primary-500);
    background-color: inherit;
    outline: none;

    &:focus::placeholder {
      color: var(--color-gray-500);
    }
  }

  &-span {
    display: none;
    background-color: inherit;
  }

  &-button {
    display: flex;
    align-items: center;
    background-color: inherit;
    outline: none;

    &::after {
      @include regular-3xl();

      font-family: var(--icon-font);
      content: var(--icon-search);
      color: var(--color-gray-900);
      cursor: pointer;
    }
  }

  &-input-wrapper--is-disabled {
    border: var(--border-xs) solid var(--color-gray-300);
    background-color: var(--color-gray-50);

    .cmp-filterbar__search-input {
      border: none;
    }

    .cmp-filterbar__search-button::after,
    .cmp-filterbar__search-input,
    .cmp-filterbar__search-input::placeholder {
      color: var(--color-gray-300);
    }
  }
}
