@import '@react/common.scss';

.cmp-filterbar__checkbox {
  &-item {
    @include regular-md;

    list-style: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-right: var(--spacing-sm);
    flex-wrap: wrap;
    width: 100%;

    &:focus {
      outline: var(--border-m) solid var(--color-focus-500);
      border-radius: var(--border-s);
    }

    &:hover {
      @media #{$mq-desktop} {
        .cmp-filterbar__child-checkboxes {
          background-color: var(--color-white);
        }
      }
    }

    &--is-nested {
      padding-left: var(--spacing-lg);
    }
  }

  &-item-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-xxs) var(--spacing-md);

    @media #{$mq-mobile} {
      padding: var(--spacing-xxs) var(--spacing-xs);
    }
  }

  &-input {
    @include bold-md;

    display: flex;
    position: relative;
    padding: var(--spacing-xxs);

    &:focus-visible,
    &:focus-within {
      outline: var(--border-m) solid var(--color-focus-500);
    }

    &::before {
      position: relative;
      width: toRem(18px);
      height: toRem(18px);
      border: var(--border-s) solid var(--color-gray-700);
      content: var(--icon-check);
      font-family: var(--icon-font);
      align-items: center;
      display: inline-flex;
      color: transparent;
      border-radius: var(--border-radius-xs);
    }

    span {
      margin: 0 0 0 var(--spacing-sm);
    }

    &--is-checked::before {
      background-color: var(--color-primary-500);
      color: var(--color-white);
      border-color: var(--color-primary-500);
    }

    &--is-disabled {
      pointer-events: none;
      color: var(--color-gray-100);

      &::before {
        pointer-events: none;
        border-color: var(--color-gray-300);
        background-color: var(--color-white);
        color: transparent;
      }

      &.cmp-filterbar__checkbox-input--is-checked::before {
        color: var(--color-gray-300);
      }
    }
  }

  &-expand {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      margin-right: 0;
    }

    &:focus {
      outline: var(--border-m) solid var(--color-focus-500);
      border-radius: var(--border-s);
      z-index: 1;
    }

    &--is-disabled {
      pointer-events: none;
      color: var(--color-gray-300);
    }
  }
}

.cmp-filterbar__child-checkboxes {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  width: 100%;
}

.cmp-filterbar__child-checkboxes--is-expanded {
  display: flex;
  flex-direction: column;
}

.cmp-filterbar__filter-checkbox {
  width: fit-content;
  margin-bottom: 0;
}

.cmp-filterbar__filter--is-on-demand {
  width: 100%;

  @media #{$mq-desktop} {
    margin-bottom: var(--spacing-lg);
  }
}
