@import '@site/styles/foundations/typography.scss';

.cmp-filterable-list__events-icon {
  &-text,
  &-text--date {
    fill: var(--color-primary-500);
  }

  &-text {
    @include bold-md;
  }

  &-text--date {
    @include bold-3xl;
  }

  &-text--month,
  &-text--year {
    @include bold-xs;
  }

  &-text--month {
    fill: var(--color-white);
  }

  &-text--year {
    fill: var(--color-gray-900);
  }
}
