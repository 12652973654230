@import '@site/styles/foundations/media_queries.scss';
@import '@site/styles/foundations/typography.scss';

.cmp-filterable-list__events-type--list {
  .cmp-filterable-list__card {
    margin-top: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    border-bottom: var(--border-xs) solid var(--color-gray-300);
    box-shadow: none;

    &-link {
      border-radius: 0;

      @media #{$mq-1} {
        gap: var(--spacing-sm);
      }
    }

    &-content {
      padding: var(--spacing-xs) 0;
    }

    &-title {
      @include bold-lg();
    }

    &-event-date-and-location {
      @include regular-sm();

      display: flex;
      flex-wrap: wrap;
      margin-bottom: var(--spacing-xs);

      &--date {
        @media #{$mq-1} {
          margin-right: var(--spacing-xxs);
        }

        span {
          color: var(--color-gray-500);
        }
      }

      &--location::before {
        content: ', ';
      }
    }

    &-description {
      @include regular-lg();

      margin-right: var(--spacing-xxl);
      margin-bottom: 0;
    }

    .cmp-filterable-list__card-read-more {
      @media #{$mq-mobile} {
        margin-top: var(--spacing-sm);
      }

      @media #{$mq-desktop} {
        position: absolute;
        right: var(--spacing-xs);
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &:not(:has(.cmp-filterable-list__card-description)) .cmp-filterable-list__card-title {
      margin-bottom: 0;
    }

    &-tag-and-icon {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      flex-direction: column;

      @media #{$mq-mobile} {
        justify-content: flex-start;
      }

      &--tag-value {
        @include bold-md();

        padding: var(--spacing-xxs);
        background-color: var(--color-success-500);
        color: var(--color-white);
        border-radius: 0 0 var(--border-radius-xs) var(--border-radius-xs);
        width: 100%;
        text-align: center;
      }

      &--icon {
        display: flex;
        padding: var(--spacing-xs) 0 var(--spacing-xxs) var(--spacing-xs);
      }
    }
  }
}
